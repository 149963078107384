const First4Theme = ({ attributes, card, index }) => {
    const { theme, isImg, imgPos, isTab } = attributes;
    const { img, title, desc, btnLabal, btnUrl, isBtn, cardUrl } = card;

    const imgEl = isImg && img && <img src={img} alt={title} />;

    console.log(isBtn);

    return (
        <a {...(cardUrl ? { href: cardUrl } : {})}
            {...(isTab ? { target: "_blank", rel: "noopener noreferrer" } : {})}
            className={`card card-${index} ${theme} first4Theme`}
            key={index} >
            {'first' === imgPos && imgEl}

            <div className="content">
                {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
                {desc && <p dangerouslySetInnerHTML={{ __html: desc }} />}
                {(btnLabal && isBtn) && (
                    <div className="btnWrapper">
                        <a href={btnUrl} dangerouslySetInnerHTML={{ __html: btnLabal }} />
                    </div>
                )}
            </div>

            {'last' === imgPos && imgEl}
        </a>
    );
};

export default First4Theme;
